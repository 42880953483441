import $ from 'jquery';

$(function() {
  window.addEventListener('load', () => {
    toggleTab();
    jumpHash();
  });  

  window.addEventListener( 'resize',() => {});

  window.addEventListener( 'scroll',() => {});

  const toggleTab = () => {
    $('.headNav__item').click(function() {
      if($(this).hasClass('is-current')) {
        $('.headNav__item').removeClass('is-current');
        $('.menuContent__type').removeClass('is-open');
        return;
      }
      const index = $('.headNav__item').index(this);
      $('.headNav__item').removeClass('is-current');
      $(this).addClass('is-current');
      $('.menuContent__type').removeClass('is-open');
      $('.menuContent__type').eq(index).addClass('is-open');
    });

    $('.menuContent__typeItem').click(function() {
      $('.menuContent__type').removeClass('is-open');
      $('.headNav__item').removeClass('is-current');
    });
  };

  const jumpHash = () => {
    $('a[href^="#"]').click(function() {
    	const speed = 1000;
			const href= $(this).attr("href");
			const target = $(href == "#" || href == "" ? 'html' : href);
			const position = target.offset().top

			$("html, body").animate({scrollTop:position}, speed, "swing");
			return false;
		});

	}
});